import React, { Suspense, lazy } from 'react';
import './App.css';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

// const ProjectCard = lazy(() => import('./components/ProjectCard'));
const Contact = lazy(() => import('./components/Contact'));
const AboutMe = lazy(() => import('./components/AboutMe'));
const Home = lazy(() => import('./components/Home'));

function App() {
  // const projects = [
  //   {
  //     name: 'Vehicle Maintenance App',
  //     link: 'https://vehicle-maintnance.web.app/',
  //     description:
  //       'An app to track vehicle maintenance. Built with React and Firebase. Features include user authentication, and more. Still in development, but most of functions are operable.',
  //     image: '/images/optimized/vehicle-maintenance-app.webp',
  //   },
  //   // {
  //   //   name: 'Project 2',
  //   //   description: 'This is a description for Project 2.',
  //   //   image: 'path/to/image2.jpg',
  //   // },
  // ];

  return (
    <div className="App">
      <header id="home">
        <Header />
        <Navigation />
      </header>
      <main className="flex flex-wrap justify-around">
        <section>
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        </section>
        <section id="about">
          <Suspense fallback={<div>Loading...</div>}>
            <AboutMe />
          </Suspense>
        </section>
        <section
          id="projects"
          className="flex flex-col items-center justify-center"
        >
          {/* <h2 className="section-title text-2xl font-bold text-blue-500 animate-pulse">
            Project I'm Working On
          </h2>
          <Suspense fallback={<div>Loading...</div>}>
            <div className="projects-grid">
              {projects.map((project, index) => (
                <ProjectCard key={index} project={project} />
              ))}
            </div>
          </Suspense> */}
        </section>
      </main>
      <footer>
        <div id="contact" className="flex flex-col items-center justify-center">
          <h2 className="section-title text-2xl font-bold text-blue-500 animate-pulse">
            Contact Me
          </h2>
          <Suspense fallback={<div>Loading...</div>}>
            <Contact />
          </Suspense>
        </div>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
